import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import Api from '../../Api';
import { useNavigate } from "react-router-dom";
import Topbar from "../global/Topbar";



const TenantListing = ({ isLogging }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [taskData, setTaskData] = useState([])



  useEffect(() => {
    fetchData();
  }, []);



  const fetchData = async () => { 
    try { 
     
      const response = await Api.get_All_Task();
    if (response.data && Array.isArray(response.data.data)) {
    //  console.log(response.data.data)
    setTaskData(mapUserData(response.data.data));
     
    console.log(response.data.data)
    } else {
      console.error('Data is not an array or is missing:', response.data.data);
    }
    } catch (error) {
      console.error('Error fetching data:', error);
    
    }
  };










  useEffect(() => {
    !isLogging && navigate('/')
  }, [])

  const columns = [
    { field: 'sno', headerName: 'S.No', width:40 ,  },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'task_name', headerName: 'Task Name', width: 120 },
    { field: 'percent_complete_indicator', headerName: 'Percent Complete Indicator', width: 170 },
    { field: 'responsible_user', headerName: 'Responsible User', width: 170  },
    { field: 'due_date', headerName: 'Due Date', width: 170 },

    {
      field: 'task_owner',
      headerName: 'Task Owner',
       width: 170

   
    },
    {
      field: 'status',
      headerName: 'Status'
      , width: 170,
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="error" style={{
          backgroundColor: params.row.status =="Started" ? "#0DCEB0" : "#FFD505"
        
        }}
 
        >
      {params.row.status}
        </Button>
      ),
    }

    ,

    {
      field: ' ',
      headerName: ' '
      , width: 20,
  
     
      headerName: ' ',
      flex: 1,
      width: 100,

    },
    {
      field: 'blank',
      headerName: '',
       width: 20 ,
      renderCell: (params) => (
        <img src="/img/star.png" alt="" />
      ),
    }

    ,
    {
      field: 'action',
      headerName: 'Action',
       width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    }
  
  ];

  // useEffect(() => {
  //   const staticData = [
  //     { id: 1, sno: 1, task_name: 'Task 1', percent_complete_indicator: '50%', responsible_user: 'User 1', due_date: "12/32/3939", task_owner: "kareena" },
  //     { id: 2, sno: 2, task_name: 'Task 2', percent_complete_indicator: '30%', responsible_user: 'User 2', due_date: "12/32/0939", task_owner: "yash" },
  //     // Add more tasks as needed
  //   ];
  //   setUserData(staticData);
  //   // fetch(`${BACKEND_URL}/admin/users-tenent`)
  //   //   .then((response) => response.json())
  //   //   .then((data) => {
  //   //     setUserData(mapUserData(data.data))
  //   //   })
  //   //   .catch((error) => console.error('Error fetching user data:', error));
  // }, []
  //   //  [isSnackbarOpen]
  // );

  const mapUserData = (data) => {
  
    return  data.map((d, index) => ({
      
      sno: index + 1,
      id: d._id || index,
      // login: user.login,
      task_name: d.tName || 'No',
      percent_complete_indicator: d.priority || 'No',
      responsible_user: d.responsible ||  'No',
      status:d.status ||"No",
      due_date:d.dueDate.slice(0,10) || "No",
      task_owner:d.taskOwner || "No",
      
    }));
  };




  // const handleDeleteConfirm = async () => {
  //   try {
  //     await axios.get(`${BACKEND_URL}/admin/delete-user/${selectedUserId}`);
  //     setIsSnackbarOpen(true);
  //     setIsDeleteDialogOpen(false);
  //   } catch (error) {
  //     console.error('Error deleting user:', error);
  //   }
  // };


  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
   <>
        <Topbar />

        <Box m="20px" className="margin_in_mobile_ipad">
      <Header title="Tasks" subTitle="New Task"  />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: 'none',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius:"10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}
      >
        <DataGrid components={{ Toolbar: GridToolbar }} checkboxSelection rows={ taskData} columns={columns} />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="User deleted successfully" />
    </Box>
   </>
  );
};

export default TenantListing;
