import React, { useState, useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { useParams } from "react-router-dom";
import { getCookie } from "../../components/Cookies";



// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const EditManager = ({ onClose }) => {

  const navigate = useNavigate()

  const { id } = useParams();

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const token = getCookie("leadmanagement")

  const [adminData, setAdminData] = useState(null)
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");





  const handleChangePasswords = async () => {
    try {


      if (newPassword.length < 8) {
        return toast.error("Password length should be minimum  8 characters");
      }
      if (newPassword !== confirmNewPassword) {
        return toast.error("New password should not be same as current password");
      }

      if (!newPassword) {
        return toast.error("fill new password");
      }



      const response = await Api.SuperchangePassword(id, { newPassword }, token);

      if (response.data.message === "password changed successfully") {
        setChangePassword(!changePassword)

        toast.success("Password updated successfully");
      }


      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {

      console.error("Error changing password:", error);
      toast.error(error.response.data.message);
    }


  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    try {
      const response = await Api.getAdminById(id);
      console.log(response.data.data)
      setAdminData(response.data.data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }






  const backToPage = () => {
    navigate("/users")
  }


  const handleUpdate = async () => {
    try {

      const response = await Api.editAdmin(id, { ...adminData });


      console.log("API Response:", response);

      if (response.status === 200) {
        toast.success("Successfully updated", {
          autoClose: 800,
          onClose: () => navigate("/users"),
        });

      }
    } catch (error) {

      console.error("Error updating property:", error);
    }
  };










  return (
    <>
      <Topbar />
      {adminData && (

        <>
          <Box m="20px" className="margin_in_mobile_ipad"

          >

            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 20px", alignItems: "center", backgroundColor: "white", zIndex: "9999999", borderRadius: "10px" }}>

              <div style={{ display: "flex", padding: "20px" }}>
                <div style={{ display: "flex", gap: "10px" }}>

                  <Button sx={{
                    // backgroundColor: "#9B56FF", 
                    color: "#9B56FF",
                    border: " 1px solid #9B56FF",
                    '&:hover': {
                      backgroundColor: '#ffffff',
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF"
                    }
                  }}

                    onClick={() => {
                      handleUpdate();
                    }}
                  >Update</Button>

                  <Button sx={{
                    backgroundColor: "#9B56FF", color: "white", '&:hover': {
                      backgroundColor: '#ffffff',
                      color: "#9B56FF",
                      border: " 1px solid #9B56FF"
                    }
                  }}

                    onClick={backToPage}> Cancel</Button>

                </div>
              </div>

            </div>





            <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

              <div className="column_form" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>

                <Box sx={{


                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }}  >

                  <Typography

                    variant="h4"

                    fontWeight="550"
                    sx={{ m: "0 0 5px 0", }}

                    className="heading_animation"
                  >
                    Admin Information

                  </Typography>

                  <br /><br />




                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{
                      display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                    }}
                    noValidate
                    autoComplete="off"
                  >


                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="First Name"
                      value={adminData.firstName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setAdminData({ ...adminData, firstName: inputText });
                        }
                      }}
                      variant="outlined"
                    />

                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Last Name"
                      value={adminData.lastName}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 15) {
                          setAdminData({ ...adminData, lastName: inputText });
                        }
                      }}
                      variant="outlined"
                    />






                    <TextField
                      className="class_input"
                      id="outlined-controlled"
                      label="Email"
                      value={adminData.email}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 35) {
                          setAdminData({ ...adminData, email: inputText });
                        }
                      }}
                      variant="outlined"
                    />






                    <TextField
                      type="number"
                      className="class_input"
                      id="outlined-controlled"
                      label="Phone"
                      value={adminData.mobileNumber}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (inputText.length <= 10) {
                          setAdminData({ ...adminData, mobileNumber: inputText });
                        }
                      }}
                      variant="outlined"
                    />




                  </Box></Box>


                <Box sx={{


                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px"
                  , padding: "20px"
                  , width: "100%"


                }}    >
                  <Typography

                    variant="h4"

                    fontWeight="550"

                    sx={{ m: "0 0 5px 0", }}
                    className="heading_animation"
                  >
                    Company Information

                  </Typography>
                  <br /><br />

                  <Box
                    component="form" className="LMS-Info-section"
                    sx={{
                      display: "grid", gridTemplateColumns: "auto auto auto ", gap: "20px"
                    }}
                    noValidate
                    autoComplete="off">

                    <TextField
                      className="class_input"
                      id="outlined-uncontrolled"
                      label="Industry"
                      value={adminData.industry}
                      onChange={(e) => setAdminData({ ...adminData, industry: e.target.value })}
                    />

                    <TextField
                      className="class_input"
                      id="outlined-uncontrolled"
                      label="Owner"
                      value={adminData.owner}
                      onChange={(e) => setAdminData({ ...adminData, owner: e.target.value })}
                    />

                    <TextField
                      className="class_input"
                      id="outlined-uncontrolled"
                      label="WebSite"
                      value={adminData.website}
                      onChange={(e) => setAdminData({ ...adminData, website: e.target.value })}
                    />

                    <Grid sx={{ marginTop: "-10px" }}>
                      <div>Number of Employees</div>
                      <FormControl fullWidth>
                        <Select
                          sx={{ height: "40px", minWidth: "180px" }} // Added minWidth for fixed width
                          labelId="second-select-label"
                          id="second-select"
                          fullWidth
                          value={adminData.employNumber}
                          onChange={(e) => setAdminData({ ...adminData, employNumber: e.target.value })}
                        >
                          <MenuItem value="1-9employees">1-9 employees</MenuItem>
                          <MenuItem value="10-50employees">10-50 employees</MenuItem>
                          <MenuItem value="50-200employees">50-200 employees</MenuItem>
                          <MenuItem value="200+">more than 200 employees</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <TextField
                      className="class_input"
                      id="outlined-uncontrolled"
                      label="Lead Source"
                      value={adminData.leadSource}
                      onChange={(e) => setAdminData({ ...adminData, leadSource: e.target.value })}
                    />

                  </Box>
                </Box>


              </div>
              <br />
              <Box sx={{


                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px"
                , padding: "20px"
                , width: "100%"


              }}  >

                <Typography

                  variant="h4"

                  fontWeight="550"
                  sx={{ m: "0 0 5px 0", }}

                  className="heading_animation"
                >
                  Change Password

                </Typography>

                <br /><br />




                <Box
                  component="form" className="LMS-Info-section"
                  sx={{
                    display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
                  }}
                  noValidate
                  autoComplete="off"
                >



                  <TextField
                    className="class_input"
                    type="password"
                    id="outlined-controlled"
                    label="New password"
                    value={newPassword}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setNewPassword(inputText);

                    }}
                    variant="outlined"
                  />







                  <TextField
                    className="class_input"
                    id="outlined-controlled"
                    type="password"
                    label="Confim Password"
                    value={confirmNewPassword}
                    onChange={(e) => {
                      const inputText = e.target.value;

                      setConfirmNewPassword(inputText);

                    }}
                    variant="outlined"
                  />


                  <div style={{ display: "flex", marginTop: "-3px", alignItems: "center" }}>


                    <div style={{ display: "flex", padding: "20px" }}>
                      <div style={{ display: "flex", gap: "10px" }}>

                        <Button sx={{

                          color: "#9B56FF",
                          border: " 1px solid #9B56FF",
                          '&:hover': {
                            backgroundColor: '#ffffff',
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF"
                          }
                        }}

                          onClick={() => {
                            handleChangePasswords();
                          }}
                        >Change Password</Button>


                        <Button sx={{
                          backgroundColor: "#9B56FF", color: "white", '&:hover': {
                            backgroundColor: '#ffffff',
                            color: "#9B56FF",
                            border: " 1px solid #9B56FF"
                          }
                        }}

                          onClick={backToPage}> Cancel</Button>

                      </div>
                    </div>


                  </div>


                </Box>

              </Box>
            </div>
            <ToastContainer />
          </Box>
        </>
      )}
    </>

  );
};

export default EditManager;
