import React, { useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@mui/material';
import { Checkbox, FormControlLabel } from "@mui/material";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Topbar from "../global/Topbar";
import { getCookie } from "../../components/Cookies";


// import './side.scss', { useState }
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CreateManager = ({ onClose }) => {


  const navigate = useNavigate()
const token = getCookie("leadmanagement")

  const [isChecked, setIsChecked] = useState(false); // State to hold the checked state of the checkbox

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the checked state when the checkbox is clicked
  };

  const [adminData, setAdminData] = useState({
    firstName: "", lastName: "", email: "", mobileNumber: "", 
    industry: "", owner: "", website: "", employNumber: "", leadSource: "",
  })
  const handleSave = async () => {
    if(
      !adminData.firstName
    ){
      return toast.error("first name must be required")
    }  if(
      !adminData.lastName
    ){
      return toast.error("last name must be required")
    }if(
      !adminData.email
    ){
      return toast.error("email must be required")
    }if(
      !adminData.mobileNumber
    ){
      return toast.error("mobile Number must be required")
    }if(
      !adminData.password
    ){
      return toast.error("password must be required")
    }if(
      !adminData.industry
    ){
      return toast.error("industry must be required")
    }if(
      !adminData.owner
    ){
      return toast.error("owner must be required")
    }if(
      !adminData.website
    ){
      return toast.error("website must be required")
    }if(
      !adminData.employNumber
    ){
      return toast.error(" Number of employe must be required")
    }if(
      !adminData.leadSource
    ){
      return toast.error("lead Source must be required")
    }



    try {
      const response = await Api.create_Admin(adminData , token);
      console.log("Response from createLead API:", response.data.message);
      if (response.data.message === "Admin created successfully") {
        toast.success('Successfully Created');
        navigate("/users")
      }
      else {
        console.log("error")

      }


    } catch (error) {
      console.error('Error during saving lead:', error);
      toast.error(error.message)
    }
  };


  const backToPage = () => {
    navigate("/users")
  }










  return (
    <>
     <Topbar />
      <Box m="20px" className="margin_in_mobile_ipad"

        sx={{

          // border: 'none',



          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          // borderRadius: "10px"

          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }}

      >
     

        


        <div style={{ padding: " 20px", alignItems: "center" }} className="padding_in_mobile_ipad">

<div  className="column_form" style={{display:"flex" ,justifyContent:"space-between" , gap:"20px"}}>

<Box      sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}  >   

<Typography

variant="h4"

fontWeight="550"
sx={{ m: "0 0 5px 0", }}

className="heading_animation"
>
Contact Information

</Typography>

<br /><br />




<Box
component="form" className="LMS-Info-section"
sx={{
  display: "grid", gridTemplateColumns: "auto auto   ", gap: "20px"
}}
noValidate
autoComplete="off"
>
{/* <TextField
className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={leadData.firstName}
  onChange={(e) => setLeadData({ ...leadData, firstName: e.target.value })}

/> */}

<TextField
  className="class_input"
  id="outlined-controlled"
  label="First Name"
  value={adminData.firstName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setAdminData({ ...adminData, firstName: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
  className="class_input"
  id="outlined-controlled"
  label="Last Name"
  value={adminData.lastName}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 15) {
      setAdminData({ ...adminData, lastName: inputText });
    }
  }}
  variant="outlined"
/>



{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Last Name"
  value={leadData.lastName}
  onChange={(e) => setLeadData({ ...leadData, lastName: e.target.value })}
/> */}



<TextField
  className="class_input"
  id="outlined-controlled"
  label="Email"
  value={adminData.email}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 35) {
      setAdminData({ ...adminData, email: inputText });
    }
  }}
  variant="outlined"
/>


{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Email"
  value={leadData.email}
  onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
/> */}




<TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Phone"
  value={adminData.mobileNumber}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setAdminData({ ...adminData, mobileNumber: inputText });
    }
  }}
  variant="outlined"
/>

<TextField
type="password"
  className="class_input"
  id="outlined-controlled"
  label="Password"
  value={adminData.password}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setAdminData({ ...adminData, password: inputText });
    }
  }}
  variant="outlined"
/>

{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Phone"
  value={leadData.phoneNumber}
  onChange={(e) => setLeadData({ ...leadData, phoneNumber: e.target.value })}
/> */}


{/* <TextField
type="number"
  className="class_input"
  id="outlined-controlled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => {
    const inputText = e.target.value;
    if (inputText.length <= 10) {
      setLeadData({ ...leadData, fax: inputText });
    }
  }}
  variant="outlined"
/> */}
{/* <TextField
className="class_input"
  id="outlined-uncontrolled"
  label="Fax"
  value={leadData.fax}
  onChange={(e) => setLeadData({ ...leadData, fax: e.target.value })}
/> */}

</Box></Box>

<Box  sx={{


boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
borderRadius: "10px"
, padding:"20px"
, width:"100%"


}}    >
        <Typography

variant="h4"

fontWeight="550"

sx={{ m: "0 0 5px 0", }}
className="heading_animation"
>
Company Information

</Typography>
<br /><br />

<Box
                      component="form" className="LMS-Info-section"
                      sx={{
                        display: "grid", gridTemplateColumns: "auto auto auto ", gap: "20px"
                      }}
                      noValidate
                      autoComplete="off">

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Industry"
                        value={adminData.industry}
                        onChange={(e) => setAdminData({ ...adminData, industry: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Owner"
                        value={adminData.owner}
                        onChange={(e) => setAdminData({ ...adminData, owner: e.target.value })}
                      />

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="WebSite"
                        value={adminData.website}
                        onChange={(e) => setAdminData({ ...adminData, website: e.target.value })}
                      />
<Grid sx={{ marginTop: "-10px" }}>
  <div>Number of Employees</div>
  <FormControl fullWidth>
    <Select
      sx={{ height: "40px", minWidth: "180px" }} // Added minWidth for fixed width
      labelId="second-select-label"
      id="second-select"
      fullWidth
      value={adminData.employNumber}
      onChange={(e) => setAdminData({ ...adminData, employNumber: e.target.value })}
    >
      <MenuItem value="1-9employees">1-9 employees</MenuItem>
      <MenuItem value="10-50employees">10-50 employees</MenuItem>
      <MenuItem value="50-200employees">50-200 employees</MenuItem>
      <MenuItem value="200+">more than 200 employees</MenuItem>
    </Select>
  </FormControl>
</Grid>

                      <TextField
                        className="class_input"
                        id="outlined-uncontrolled"
                        label="Lead Source"
                        value={adminData.leadSource}
                        onChange={(e) => setAdminData({ ...adminData, leadSource: e.target.value })}
                      />

                    </Box>
        </Box>

</div>


      


        



      

        
         

      

          <br />


         

      

              
        



           
       


           
     


       



        
      


      

    








            <div style={{ display: "flex", justifyContent: "end",  alignItems: "center"}}>
        
      
            <div style={{ display: "flex", gap: "10px" }}>

              <Button sx={{
                // backgroundColor: "#9B56FF", 
                color: "#9B56FF",
                border: " 1px solid #9B56FF",
                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={() => { handleSave() }}
              >Save</Button>


              <Button sx={{
                backgroundColor: "#9B56FF", color: "white", '&:hover': {
                  backgroundColor: '#ffffff',
                  color: "#9B56FF",
                  border: " 1px solid #9B56FF"
                }
              }}

                onClick={backToPage}> Cancel</Button>

            </div>
         

        </div>
   


        </div>
          <ToastContainer/>
      </Box>
    </>
  

  );
};

export default CreateManager;
