import axios from "axios"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const API_KEY = process.env.REACT_APP_API_KEY;



const postsApi = axios.create({
  baseURL: BACKEND_URL,
});

postsApi.interceptors.request.use((config) => {
  config.headers['x-api-key'] = API_KEY;
  return config;
}, (error) => {
  return Promise.reject(error);
});
const Api = {

  signup: (data) => postsApi.post("/userRoute/signup", data),

  // check_Mobile_Email:(postData)=>postsApi.post("/check-email-phone",postData),

  login: (postData) => postsApi.post('/userRoute/login', postData),




  SuperchangePassword:(id, data,token ) => postsApi.put(`/userRoute/super-admin/change-password/${id}`,data,token ),


  create_Task: (data) => postsApi.post("/create/task", data),

  create_Contact: (data) => postsApi.post("/create/contact", data),

  create_Compony: (data) => postsApi.post("/create/company", data),

  createLead: (data) => postsApi.post("/create-lead", data),

  create_Deal: (data) => postsApi.post("/create/deal", data),

  create_project: (data) => postsApi.post('/create-project', data),










  Delete_lead: (id, config) => postsApi.get(`/leadRoute/delete/lead/${id}`, config),

  deleteContact: (id, config) => postsApi.get(`/delete/contact/${id}`, config),

  deleteCompany: (id, config) => postsApi.get(`/delete/company/${id}`, config),

  deleteProject: (id, config) => postsApi.get(`/delete/project/${id}`, config),








  get_All_Task: () => postsApi.get("/get-all/task"),

  get_All_Contact: () => postsApi.get("/get-all/contact"),

  getAll_company: () => postsApi.get("/get-all/comp"),

  get_All_Leads: (token) => postsApi.get("/leadRoute/get-all/lead" , token),

  getAll_Deal: () => postsApi.get("/get-all/deals"),

  get_All_Project: () => postsApi.get("/get-all-project"),





  get_LeadsBy_Id: (id) => postsApi.get(`/get-lead-by-id/${id}`),

  getContactById: (id) => postsApi.get(`/get-contact/${id}`),

  getCompById: (id) => postsApi.get(`/get-comp/${id}`),

  getProjectById: (id) => postsApi.get(`/get-project/${id}`),







  update_Lead: (id, data, token) => postsApi.put(`/leadRoute/update-lead/${id}`, data, token),

  updateContact: (id, data, token,) => postsApi.put(`/update-contact/${id}`, data, token),

  updateCompany: (id, data, token,) => postsApi.put(`/update-company/${id}`, data, token),

  updateProject: (id, data, token,) => postsApi.put(`/update-project/${id}`, data, token),


  //=====================super admin section ======================================//


  create_Admin:(data, token) => postsApi.post("/superAdminRoute/create/admin" , data ,token),

  getAllAdmin:() => postsApi.get("/superAdminRoute/get/admin"),
 
  getAdminById:(id)=>postsApi.get(`/superAdminRoute/get-admin/${id}`),

  editAdmin: (id, data, token,) => postsApi.put(`/superAdminRoute/update-admin/${id}`, data, token),

  deleteAdmin: (id, config) => postsApi.get(`/superAdminRoute/delete/admin/${id}`, config),

};

export default Api