import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import SidePopup from "../CreateLead";
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Topbar from "../global/Topbar";


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const Leads = ({isLogging}) => {

  const [isSidePopupOpen, setIsSidePopupOpen] = useState(false);





const openPopup = () =>{
  navigate("/create-lead");

}







  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectType, setSelectType] = useState();
const [lead ,setLead] =useState([])
const [selectedUserId, setSelectedUserId] = useState(null);
const [isDelete, setIsDelete] = useState(false);


useEffect(()=>{
fetchData()
},[isDelete])
  
const fetchData =async ()=>{

  try {
    const response =await Api.get_All_Leads()
    if(response.data && Array.isArray(response.data.data)){
      setLead(mapPlotData(response.data.data));
      // setLead(response.data.data)
      console.log(response.data.data);
      
    }else{
      console.error('Data is not an array or is missing:', response.data.data);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
  

  useEffect(()=>{
    !isLogging && navigate('/')
  },[])



  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
    

      await Api.Delete_lead(selectedUserId);
      setIsDelete(!isDelete)
      setIsDeleteDialogOpen(false);
    
    } catch (error) {
     
      console.error("Error deleting user:", error);
    }
  };



  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    { field: "full_name", headerName: "Full Name", width: 120 ,

    renderCell: (params) => (
      <Link to={`/lead-details/${params.row.id}`}>
        {params.value}
        </Link>
    ),
  
  },


    { field: 'email', headerName: 'Email ', width: 120 },
    // { field: "title", headerName: "Title", width: 170 },
    { field: 'company', headerName: 'Comapny', width: 120 },
    // { field: 'phone', headerName: 'Phone', width: 170 },

    { field: 'lead_source', headerName: 'Lead Source ', width: 120 },

    { field: 'lead_status', headerName: 'Lead Status ', width: 120 },
    { field: 'lead_created', headerName: 'Lead Created ', width: 120  },
    // { field: 'lead_owner', headerName: 'Lead Owner ', width: 170 },

    {
      field: 'follow_up',
      headerName: 'Add Followup'
      , width: 120 ,
      renderCell: (params) => (
        <Link to={`/google-calendar/${params.row.id}`}> 
        <AddCircleOutlineOutlinedIcon />
      </Link>
      ),

    
    },
    {
      field: 'edit_lead',
      headerName: 'Edit Lead'
      , width: 100 ,
      renderCell: (params) => (
        <Link to={`/edit-lead/${params.row.id}`}>
          <EditIcon />
        </Link>
      ),

    
    }

    ,  {
      field: 'delete_lead',
      headerName: 'Delete Lead'
      , width: 100 ,
      renderCell: (params) => (
      <button 
      onClick={() => handleDeleteClick(params.row.id)}
       ><DeleteForeverOutlinedIcon/></button>
      ),

    
    }

    ,

    ,{
      field: 'action',
      headerName: 'Action',
       width: 40 ,
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },





  ];




  const mapPlotData = (data) => {
    return data.map((d, index) => ({
      sno: index + 1,
      id: d._id ||index  ,
      full_name: d.firstName || "No",
      title: d.title || "No",
      company: d.industry || "No",
      phone: d.phoneNumber,
      email:d.email,
      lead_status:d.leadStatus,
      lead_created:d.createdAt.slice(0,10),
      lead_owner:d.owner,
      lead_source:d.leadSource

    }));
  };

  return (
  <>
       <Topbar />

       <Box m="20px" className="margin_in_mobile_ipad" >
 

 <Header title="Leads" subTitle="New Lead" openPopup={openPopup} />
 <Box
   m="20px 0 0 0"
   height="75vh"
   sx={{
     "& .MuiDataGrid-root": {
   
       boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
       borderRadius:"10px"
     },
 
   }}
 >
   <DataGrid
     components={{ Toolbar: GridToolbar }}
     checkboxSelection
     rows={lead}
     // rows={plotData}

     columns={columns}
   />
 </Box>


     {/* Delete Confirmation Dialog */}
     <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
       <DialogTitle>Confirm Delete</DialogTitle>
       <DialogContent>
         Are you sure you want to delete this Lead?
       </DialogContent>
       <DialogActions>
         <Button onClick={handleDeleteCancel} color="primary">
           Cancel
         </Button>
         <Button onClick={handleDeleteConfirm} color="error">
           Delete
         </Button>
       </DialogActions>
     </Dialog>






     </Box>
  </>
  );
};

export default Leads;
