import React from "react";
import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
// import DropdownItem from "../../components/dropdown";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


const DropdownItem = ({ title, to, icon, selected, setSelected, children }) => {
  console.log()
  console.log(children)
  const theme = useTheme(); 
  const colors = tokens(theme.palette.mode);
  
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setSelected(title)
    setOpen(!open);
  };
 

  return (
    <>
      <MenuItem
       active={selected === title}
        style={{
          color: colors.grey[100] ,
       
        }}
       
        icon={icon}
        onClick={handleToggle}
        
      >
       
        <Typography style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>{title} <ExpandMoreIcon /></Typography>
        
      </MenuItem>
      

      {open && children && children.map((child, index) => ( 
        <MenuItem
          key={index}
          active={selected === child.title}
          style={{
            color: colors.grey[100],
          }}
          onClick={() => setSelected(child.title)}
          
        >
          <Typography  style={{padding:"10px 0px 0px 40px"}}>{child.title}</Typography>
          <Link to={child.to} />
        </MenuItem>
      ))}
    </>
  );
};

const Item = ({ title, to, icon, selected, setSelected, childItem }) => { 
  
  console.log(childItem)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (childItem && childItem.length > 0) {
    return (


      

   
      DropdownItem ( {  title:title,
        to:to,
        icon:icon,
        selected:selected,
        setSelected:setSelected,
        children :childItem })

     
    );
  } else {


    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
      
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  }
};



const MobileSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(  "Dashboard" );
 


 











  return (
    <Box

    
    
      sx={{
        // "& .pro-sidebar-inner": {
        //   background: `${colors.primary[400]} !important`,
        // },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 0px 5px 10px !important",
        },
        // "& .pro-inner-item:hover": {
        //   backgroundColor: "#9B56FF !important",
        //   color:"white"
        // },
        "& .pro-menu-item.active": {
          backgroundColor: "#9B56FF !important",
          color: "white !important "
        },
        

      
        
      
  

   

      }}
      className='yash '
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
        

          {/* {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Homlea
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Admin
                </Typography>
              </Box>
            </Box>
          )} */}





          {/* <div style={{display:isCollapsed?"none" :"flex"
  // "flex"
   , justifyContent:"center"}}>
      <img src="/img/logo.png" alt="" />
     </div> */}


          {/* <br /> */}




          <Box paddingLeft={isCollapsed ? undefined : "2%"} >



    

<Item
  title="Dashboard"
  icon={<HomeOutlinedIcon />}
  selected={selected}
  setSelected={setSelected}
  childItem = {[{title:"Deals Dashboard" , to:"/dashboard"} , {title:"Projects Dashboard" , to:"/project-dashboard"},
  {title:"Leads Dashboard" , to:"/leads-dashboard"} ,
    ]}
>




</Item>

   
            {/* <Item
              title="Task"
              to="/task"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Contacts"
              to="/contact"
              icon={<ContactPageOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
       


            <Item
              title="Companies"
              to="/companies"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           
            <Item
              title="Leads"
              to="/leads"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Item
              title="Deals"
              to="/deals"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Item
              title="Projects"
              to="/projects"
              icon={<AccountTreeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Reports"
              to="/reports"
              icon={<ReportOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

    
  
{/* <Item
  title="Invoices"
  icon={<HomeOutlinedIcon />}
  selected={selected}
  setSelected={setSelected}
  childItem = {[{title:"Invoices List" , to:"/invoices-list"} , {title:"Invoices Grid" , to:"/invoices-grid"},
  {title:" Add Invoices" , to:"/add-invoice"} ,
    {title:"Edit Invoices" , to:"/edit-invoice"} ,  {title:" Invoices Details" , to:"/invoices-details"}  , 
     {title:"Invoices Settings" , to:"/invoices-settings"}]}
>
 



</Item> */}


<Item
              title="Settings"
              to="/setting"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
{/* <Item
              title="Add Invoice"
              to="/add-invoice"
              icon={<ReceiptOutlinedIcon />}
            
              selected={selected}
              setSelected={setSelected} 
            /> */}



            

          




     
              
           




















            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Add user
            </Typography>
            <Item
              title="Add user"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default MobileSidebar;
