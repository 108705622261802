import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Task from "./scenes/tenentListing";
import Deals from "./scenes/deals";
import Projects from "./scenes/projects";
import Report from "./scenes/report";
import Contact from "./scenes/contact";
import Leads from "./scenes/leads";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Companies from "./scenes/companies";
import PlotListing from "./scenes/plotListing";
import EditHost from "./scenes/editHost";
import EditProperty from "./scenes/editProperties";
import LoginPage from "./scenes/loginPage";
import Step1 from "./scenes/addListing/Step1"
import Step2 from "./scenes/addListing/Step2";
import Step3 from "./scenes/addListing/Step3";
import Step4 from "./scenes/addListing/Step4";
import PlotStep from "./scenes/addListing/StepForPlot";
import CommercialStep from "./scenes/addListing/CommercialStep";
import Invoices from "./scenes/invoice";
import InvoicesList from "./scenes/InvoiceGrid";
import InvoicesDetails from "./scenes/invoicesDetails";
import InvoicesSettings from "./scenes/invoicesSettings";
import AddInvoice from "./scenes/AddInvoice";
import Loginn from "./scenes/login2";
import Register from "./scenes/register";
import ProjectDashboard from "./scenes/projectDashboard";
import LeadsDashboard from "./scenes/leadsDashboard";
import EditInvoice from "./scenes/EditInvoice";
import Settings from "./scenes/setting";
import CreateLead from "./scenes/CreateLead";
import LeadsDetails from "./scenes/leadsDetails";
import Kanban from "./scenes/drop";
import EditLead from "./scenes/EditLead";
import Followup from "./scenes/followup";
import GoogleCalendar from "./scenes/googleCalendar";
import CreateContact from "./scenes/createContact";
import CreateCompany from "./scenes/createCompany";
import CreateProject from "./scenes/createProject";
import ContactDetails from "./scenes/contactDetails";
import EditContact from "./scenes/editContact";
import SuperAdmin from "./scenes/superAdmin/superAdmin";
import CompanyDetails from "./scenes/companyDetails";
import EditCompany from "./scenes/editCompany";
import ProjectDetails from "./scenes/projectDetails";
import EditProject from "./scenes/editProject";
import Managers from "./scenes/Managers";
import CreateManager from "./scenes/Managers/createManger";
import ManagerDetails from "./scenes/Managers/managerDetails";
import EditManager from "./scenes/Managers/editManager";

import Home from "./scenes/home";

// import Contact from "./scenes/contact";
function App({ isLogging }) {
  console.log(isLogging)
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  // const [isLogging , setisLogging] = useState(isLogging ||  false);
  const location = useLocation();



  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app ">
          {/* <Sidebar isSidebar={isSidebar} /> */}
          {isLogging &&
            <Sidebar isSidebar={isSidebar} />
          }
          <main className="content" style={{ backgroundColor: "" }}>

            <Routes>


              {/* admin routes""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""" */}

              <Route path="/" element={isLogging ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
              {/* <Route path="/" element={<Dashboard isLogging={isLogging}/>} /> */}
              <Route path="/dashboard" element={<Home isLogging={isLogging} />} /> 
             


              <Route path="/analytics-and-statistics" element={<Dashboard isLogging={isLogging} />} /> 
              <Route path="/project-dashboard" element={<ProjectDashboard isLogging={isLogging} />} />
              <Route path="/leads-dashboard" element={<LeadsDashboard isLogging={isLogging} />} />
              <Route path="/Kanban" element={<Kanban isLogging={isLogging} />} />
              <Route path="/task" element={<Task isLogging={isLogging} />} />
              <Route path="/contact" element={<Contact isLogging={isLogging} />} />
              <Route path="/create-contacts" element={<CreateContact isLogging={isLogging} />} />
              <Route path="/companies" element={<Companies isLogging={isLogging} />} />
              <Route path="/create-companies" element={<CreateCompany isLogging={isLogging} />} />
              <Route path="/register" element={<Register isLogging={isLogging} />} />
             {/* <Route path="/contacts" element={<Contacts isLogging={isLogging}/>} /> */}
              <Route path="/contacts" element={<Contacts isLogging={isLogging} />} />
              <Route path="/contact-details/:id" element={<ContactDetails isLogging={isLogging} />} />
              <Route path="/edit-contact/:id" element={<EditContact isLogging={isLogging} />} />
              <Route path="/leads" element={<Leads isLogging={isLogging} />} />
              <Route path="/create-lead" element={<CreateLead isLogging={isLogging} />} />
              <Route path="/lead-details/:id" element={<LeadsDetails isLogging={isLogging} />} />
              <Route path="/edit-lead/:id" element={<EditLead isLogging={isLogging} />} />
              <Route path="/follow-up/:value" element={<Followup isLogging={isLogging} />} />
              <Route path="/google-calendar/:id" element={<GoogleCalendar isLogging={isLogging} />} />
              <Route path="/deals" element={<Deals isLogging={isLogging} />} />
              <Route path="/projects" element={<Projects isLogging={isLogging} />} />
              <Route path="/create-project" element={<CreateProject isLogging={isLogging} />} />
              <Route path="/reports" element={<Report isLogging={isLogging} />} />
              <Route path="/invoices-list" element={<Invoices isLogging={isLogging} />} />
              <Route path="/invoices-grid" element={<InvoicesList isLogging={isLogging} />} />
              <Route path="/invoices-details" element={<InvoicesDetails isLogging={isLogging} />} />
              <Route path="/invoices-settings" element={<InvoicesSettings isLogging={isLogging} />} />
              <Route path="/add-invoice" element={<AddInvoice isLogging={isLogging} />} />
              <Route path="/edit-invoice" element={<EditInvoice isLogging={isLogging} />} />
              <Route path="/setting" element={<Settings isLogging={isLogging} />} />
              <Route path="/login" element={<Loginn isLogging={isLogging} />} />
              <Route path="/project-details/:id" element={<ProjectDetails isLogging={isLogging}/>} />
              <Route path="/edit-project/:id" element={<EditProject isLogging={isLogging}/>} />

              <Route path="/company-details/:id" element={<CompanyDetails isLogging={isLogging}/>} />
              <Route path="/edit-company/:id" element={<EditCompany isLogging={isLogging}/>} />
              <Route path="/users" element={<Managers isLogging={isLogging}/>} />
              <Route path="/create-user" element={<CreateManager isLogging={isLogging}/>} />

              


{/* super admin routes ************************************************************************************************/}



              <Route path="/super-admin" element={<SuperAdmin isLogging={isLogging} />} />

              <Route path="/admin-details/:id" element={<ManagerDetails isLogging={isLogging}/>} />
              <Route path="/edit-admin/:id" element={<EditManager isLogging={isLogging}/>} />







       

            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});
export default connect(mapStateToProps)(App);
