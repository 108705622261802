import React, { useState, useEffect } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Cards from "../../components/Card";
import Api from '../../Api';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';




import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Grid,
  Typography,
  
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  };






const Invoices = ({isLogging}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [plotData, setPlotData] = useState([]);
  const [selectedPlotId, setSelectedPlotId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  // const [selectType, setSelectType] = useState();

  const [taskData, setTaskData] = useState([])



  const handleNavigate =()=>{
    navigate("/add-invoice")
  }


  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };


  const [activeSection, setActiveSection] = useState(null);

  const handleClicked = (section) => {
    setActiveSection(section);
  };



  const [value, setValue] = React.useState('one');

  const handleChangesss = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);



  const fetchData = async () => { 
    try { 
     
      const response = await Api.get_All_Task();
    if (response.data && Array.isArray(response.data.data)) {
    //  console.log(response.data.data)
    setTaskData(mapUserData(response.data.data));
     
    console.log(response.data.data)
    } else {
      console.error('Data is not an array or is missing:', response.data.data);
    }
    } catch (error) {
      console.error('Error fetching data:', error);
    
    }
  };

 
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

const [date,setDate]= React.useState('');
  const handleDate = (event) => {
    setDate(event.target.value);
  };
const [status ,setStatus] =React.useState('')

const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const [category ,setCategory] =React.useState('')

  
const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  useEffect(()=>{
    !isLogging && navigate('/')
  },[])

  const columns = [
    { field: "sno", headerName: "S.No", width: 30 },
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "invoice_id", headerName: "Invoice ID", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    { field: 'created_on', headerName: 'Created on', flex: 1 },
    { field: 'invoice_to', headerName: 'Invoice to', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'due_date', headerName: 'Due date', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    // { field: 'lead_owner', headerName: 'Lead Owner ', flex: 1 },

    // {
    //   field: 'blank',
    //   headerName: '',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <img src="/img/star.png" alt="" />
    //   ),
    // }
    // ,

    ,{
      field: 'action',
      headerName: 'Action',
      flex: 1,
      width: 100, // Set the desired width
      renderCell: (params) => (
        <img src="/img/dots3.png" alt="" />
      ),
    },




    // { field: "location", headerName: "Location", flex: 1 },
    // { field: "type", headerName: "Type", flex: 1 },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="primary" onClick={() => handleEdit(params.row.id,params.row.type)}>
    //       Edit
    //     </Button>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   flex: 1,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="error" onClick={() => handleDeleteClick(params.row.id,params.row.type)}>
    //       Delete
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {



    const staticData = [
      { id: 1, sno: 1,invoice_id: '#123442$678',  category: 'abc ' ,  created_on:'24 Mar 2024' ,invoice_to:"Kareena Kapoor" , amount:"$326", status:"Active",due_date:"30 Mar 2024", },
      { id: 2, sno: 2, invoice_id: '#087675$543', category: 'xyz'  , created_on:'28 Mar 2024' ,invoice_to:"Baigan" , amount:"$787", status:"Not Active", due_date:"05 Apr 2024",},
      
      // Add more tasks as needed
    ];
    setPlotData(staticData);

    // fetch(`${BACKEND_URL}/admin/featured-property`)
    //   .then((response) => response.json())        
    //   .then((data) => {
    //     // console.log(data);
    //     setPlotData(mapPlotData(data.data));
    //   })
    //   .catch((error) => console.error("Error fetching plot data:", error));
  },[ ]
  //  [isSnackbarOpen]
   ); // Add isSnackbarOpen as a dependency

  const mapUserData = (data) => {
    return data.map((plot, index) => ({
      sno: index + 1,
      id: plot._id,
      title: plot.name || "No",
      userName: plot.host[0].firstName || "No",
      location: plot.address.city || "No",
      type: plot.Sub_property_selectedItem,
    }));
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };
  return (
    <Box m="20px" className="margin_in_mobile_ipad">
      <Header 
      title="Invoices" 
      subTitle="" 
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {/* <DataGrid
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          rows={plotData}
          columns={columns}
        /> */}

<div >
     <div className="respons" style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr" , width:"100%" }}>
        <FormControl sx={{ m: 1 }}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ height:"40px"}}
         icon={<PersonAddAltIcon/>} 
        >
          
          <MenuItem value="">
         <em> Select User</em>
          </MenuItem>
          <MenuItem value={10}>Russel Copeland</MenuItem>
          <MenuItem value={20}>Brian Jhonson</MenuItem>
        
          <MenuItem value={30}>Recherd Miles</MenuItem>
          <MenuItem value={40}>Handery Evan</MenuItem>
          <MenuItem value={50}>John Blair</MenuItem>
          <MenuItem value={60}>Barbara More</MenuItem>
          <MenuItem value={70}>Greg Lynch</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1 }}>
        <Select
          value={date}
          onChange={handleDate}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ height:"40px"}}
         icon={<PersonAddAltIcon/>} 
        >
          
          <MenuItem value="">
         <em>Select Date</em>
          </MenuItem>
        
          <MenuItem value={10}>Today</MenuItem>
          <MenuItem value={20}>Yesterday</MenuItem>
          <MenuItem value={30}>Last 7 days</MenuItem>
          <MenuItem value={40}>This month</MenuItem>
          <MenuItem value={50}>Last month</MenuItem>
        
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1 }}>
        <Select
          value={status}
          onChange={handleStatus}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ height:"40px"}}
         icon={<PersonAddAltIcon/>} 
        >
          
          <MenuItem value="">
         <em>Select Status</em>
          </MenuItem>
          <MenuItem value={10}>All invoices</MenuItem>
          <MenuItem value={20}>Paid</MenuItem>
          <MenuItem value={30}>Overdue</MenuItem>
          <MenuItem value={40}>Draft</MenuItem>
          <MenuItem value={50}>Recurring</MenuItem>
          <MenuItem value={60}>Cencelled</MenuItem>
         
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1 }}>
        <Select
          value={category}
          onChange={handleCategory}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ height:"40px"}}
         icon={<PersonAddAltIcon/>} 
        >
          
          <MenuItem value="">
         <em>By Category</em>
          </MenuItem>
          <MenuItem value={10}>Advertising</MenuItem>
          <MenuItem value={20}>Food</MenuItem>
          <MenuItem value={30}>Marketing</MenuItem>
          <MenuItem value={40}>Repairs</MenuItem>
          <MenuItem value={50}>Software</MenuItem>
          <MenuItem value={60}>Stationary</MenuItem>
         
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1 }}>
      <Button sx={{border:"2px solid #9B56FF" ,height:"40px" , color:"#9B56FF" }}>Generate Report</Button>
     </FormControl>
      </div>
    
   

    




    </div>





    <div className="invoices" style={{display:"flex",marginTop:"10px", justifyContent:"space-between" ,overflowX:"auto"}}>
     
   <div style={{}}>
   <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChangesss}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="All Invoices"  />
        <Tab value="two" label="Paid" />
        <Tab value="three" label="Overdue" />
        <Tab value="four" label="Draft" />
        <Tab value="five" label="Recurring" />
        <Tab value="six" label="Cancelled" />
      </Tabs>
    </Box>

{/* 
   <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Link
          style={{ color: activeSection === 'allInvoices' ? '#9B56FF' : 'black', textDecoration: 'none' }}
          to="/task"
          onClick={() => handleClicked('allInvoices')}
        >
          <h4>All Invoices</h4>
        </Link>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Link
          style={{ color: activeSection === 'paid' ? '#9B56FF' : 'black', textDecoration: 'none' }}
          to="/invoices"
          onClick={() => handleClicked('paid')}
        >
          <h4>Paid</h4>
        </Link>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Link
          style={{ color: activeSection === 'overdue' ? '#9B56FF' : 'black', textDecoration: 'none' }}
          to="/invoices"
          onClick={() => handleClicked('overdue')}
        >
          <h4>Overdue</h4>
        </Link>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Link
          style={{ color: activeSection === 'draft' ? '#9B56FF' : 'black', textDecoration: 'none' }}
          to="/invoices"
          onClick={() => handleClicked('draft')}
        >
          <h4>Draft</h4>
        </Link>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Link
          style={{ color: activeSection === 'recurring' ? '#9B56FF' : 'black', textDecoration: 'none' }}
          to="/invoices"
          onClick={() => handleClicked('recurring')}
        >
          <h4>Recurring</h4>
        </Link>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Link
          style={{ color: activeSection === 'cancelled' ? '#9B56FF' : 'black', textDecoration: 'none' }}
          to="/invoices"
          onClick={() => handleClicked('cancelled')}
        >
          <h4>Cancelled</h4>
        </Link>
      </FormControl> */}

    
     </div>



     <div>

     <FormControl sx={{ m: 1.7}}>
    <SettingsOutlinedIcon sx={{color:"#9B56FF   "}}/>
    
     </FormControl>

     <FormControl sx={{ m: 1}}>
     <Button sx={{border:"2px solid #9B56FF",height:"37.3px" ,
      color:"white ", 
      backgroundColor:"#9B56FF",
       width:"175px" ,


       "&:hover": {
color:"#9B56FF"
      }

      
       
       }} onClick={handleNavigate}>New invoice</Button>
    
     </FormControl>


     </div>
     
   </div>

   <Grid container rowSpacing={1}   className="GridControll"sx={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr" , width:"100%" }} >
  <FormControl sx={{ m: 1}} >
     <p ><Cards/></p>
    
  </FormControl>
  <FormControl sx={{ m: 1}} >
  <p ><Cards/></p>
    
  </FormControl>
  <FormControl  sx={{ m: 1}}>
  <p ><Cards/></p>
    
  </FormControl>
  <FormControl sx={{ m: 1}} >
  <p ><Cards/></p>
    
  </FormControl>
</Grid>
   



<Box m="10px"       sx={{
          "& .MuiDataGrid-root": {
            // border: 'none',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow
            borderRadius:"10px"
          },
          // "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          //   color: `${colors.grey[100]} !important`,
          // },
        }} >


   
      <Box
        m="20px 0 0 0"
        height="75vh"
  
      >

<h4 >Show Invoices Entries</h4>
        <DataGrid components={{ Toolbar: GridToolbar }} checkboxSelection rows={ plotData} columns={columns} />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="User deleted successfully" />
    </Box>

      </Box>
     

      {/* Delete Confirmation Dialog */}
      {/* <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this plot?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Success Snackbar */}
      {/* <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message="Plot deleted successfully" /> */}
    </Box>
  );
};

export default Invoices;
