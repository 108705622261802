// userReducer.js
const initialState = {
  userName: "",
  isLogging: false,
  profilePhoto: "",
  role: "",
  hostProperty: {},
};




const userReducer = (state = initialState, action) => {

  



  switch (action.type) {
    case "User_Data":
      return {
        ...state,
        userName: action.payload.userName,
        isLogging: action.payload.isLogging,
        profilePhoto: action.payload.proofilePhto,
        role: action.payload.role,
        isMenuOpen: false,
      };

      case "set_host_property":
      
      return {
      
        ...state,
        hostProperty: {
          ...state.hostProperty,
          ...action.payload, 
        },
      };
      case "clear_host_property":
        return {
          ...state,
          hostProperty: {},
        };

    case "LOGOUT_USER":
      return initialState;

      case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLogging: true
      };


      case "OPEN_MENU":
        return {
            ...state,
            isMenuOpen: true
        };

        
        case "CLOSE_MENU":
          return {
            ...state,
            isMenuOpen:false
          };

    default:
      return state;
  }
};

export default userReducer;
