import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
// import LandslideIcon from '@mui/icons-material/Landslide';
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
// import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import Topbar from "../global/Topbar";


import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;;

const mData = [4000, 3000, 2000, 2780];
const nData = [4500, 4000,2300, 3908];

const mLabels = [
  "o",
  '100',
  '150',
  '200',
 
];

const rData = [100, 75, 50, 100];
const sData = [90, 65, 40, 90];
const zLabels = [
  "2006",
  "2010",
  "2014",
  "2018"
];



const aData = [2478, 5267, 734, 784, 433, 2390, 2090];

const yLabels = [
  "2006",
  "2010",
  "2011",
  "2012",
  "2018",
  "2020",
  "2022",
];

const uData = [, 60, 50, 60, 50, 60, 50];
const pData = [, 50, 40, 50, 40, 50, 40, 60];


const xLabels = [
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
];

const chartSetting = {
  xAxis: [
  ],
  width: 400,
  height: 300,
};
const dataset = [

  {
    london: 2300,
    paris: 2408,
    newYork: 2250,
    seoul: 2478,
    year: 2000,
  },
  {
    london: 5000,
    paris: 5100,
    newYork: 5200,
    seoul: 5267,
    year: 2010,
  },
  {
    london: 7084,
    paris: 7300,
    newYork: 7342,
    seoul: 734,
    year: 2011
  },
  {
    london: 4784,
    paris: 2784,
    newYork: 6784,
    seoul: 784,
    year: 2015
  },
  {
    london: 6433,
    paris: 4433,
    newYork: 9433,
    seoul: 433,
    year: 2020
  },





];

const valueFormatter = (value) => `${value}`;




const SuperAdmin = ({isLogging}) => {
  const navigate = useNavigate()
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null)

  const Navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(()=>{
      if(!isLogging){
        navigate("/")
      }
  },[])
  

  return (
   <>
   <Topbar />

   </>
  );
};

export default SuperAdmin;
